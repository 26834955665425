import { useRef, useEffect, useState } from "react"

const useRecaptcha = () => {
  const recaptchaRef = useRef(null)
  const [responseData, setResponseData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (typeof window !== "undefined" && window.grecaptcha) {
      window.grecaptcha.ready(() => {
        console.info("reCAPTCHA ready!")
      })
    }
  }, [])

  const getToken = () => recaptchaRef.current?.getValue()

  const resetRecaptcha = () => recaptchaRef.current?.reset()

  const submitForm = async () => {
    setLoading(true)

    setError(null)

    try {
      const recaptchaToken = getToken()

      if (!recaptchaToken) {
        throw new Error("Prove that you are not a robot")
      }

      const response = await fetch("/.netlify/functions/verify-recaptcha", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(recaptchaToken),
      })

      const data = await response.json()

      setResponseData(data)

      if (data.statusCode !== 200) {
        throw new Error(data.error || "reCAPTCHA validate failed")
      }

      resetRecaptcha()

      return data
    } catch (err) {
      setError(err.message)

      console.error("reCAPTCHA error:", err)

      return null
    } finally {
      setLoading(false)
    }
  }

  return {
    recaptchaRef,
    submitForm,
    responseData,
    loading,
    error,
    resetRecaptcha,
  }
}

export default useRecaptcha
